@import "bootstrap-icons/font/bootstrap-icons.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.valid-icon {
    color: green;
}

.fill-width {
    width: 100%;
}



.mat-drawer-transition .mat-drawer-backdrop {
    position: fixed;
}

.time-range-input .mat-date-range-input-wrapper {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.list-filters .mat-mdc-form-field-infix {
    width: 100%;
}



.group-wrapper {
    position: relative;
    padding: 0px;
    margin-bottom: 24px;
    margin-top: 8px;
}

.group-wrapper:has(.group-title) {
    border-top: 1px solid #919191;
    padding-top: 24px;
}

.group-title {
    background-color: white;
    position: absolute;
    margin-top: -32px;
    line-height: 16px;
    padding-right: 12px;
    color: #D44E30;
    font-weight: bold;
}

.field_name {
    color: #D44E30;
}

.field_value {
    color: #000;
}

.detail_edit_button {
    position: absolute !important;
    right: 0px;
    z-index: 2;
    margin-right: 12px;
    color: #D44E30 !important;
}

mat-card.edit-modal {
    max-height: calc(100% - 24px);
    width: 860px;
    margin: 12px;
}

mat-card.edit-modal mat-card-content {
    overflow-y: auto;
    padding: 12px 16px;
}

mat-card.edit-modal .group-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-column-gap: 1em;
    -moz-column-gap: 1em;
    -ms-column-gap: 1em;
    column-gap: 1em;
}

mat-card.edit-modal .group-field {
    -webkit-align-self: center;
    align-self: center;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.list-right-menu {
    padding: 12px;
    overflow: visible;
    width: 400px;
}

.list-right-menu-close {
    color: inherit
}

.list-toolbar {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87) !important;
    white-space: inherit !important;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: auto !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.flex-grow {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.list-toolbar-spacer {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.d-inline-mobile {
    display: none;
}

.d-none {
    display: none !important;
}

mat-dialog-container {
    max-width: 884px !important;
}

@media screen and (max-width: 960px) {
    mat-card.edit-modal {
        width: 430px;
    }

    .d-inline-mobile {
        display: inline;
    }

    .d-none-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 652px) {
    .full-page-dialog mat-dialog-container {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        top: 0px !important;
        left: 0px !important;
    }

    .mat-mdc-dialog-content {
        max-height: unset !important;
    }
}

@media screen and (max-width: 480px) {
    mat-card.edit-modal {
        width: 100%;
        max-height: 100%;
        margin: 0px;
    }

    .list-right-menu {
        width: 100%;
    }
}

.readonly-input .mdc-text-field {
    background-color: whitesmoke;
}

.mat-column-mon,
.mat-column-tue,
.mat-column-wed,
.mat-column-thu,
.mat-column-fri,
.mat-column-missing_days,
.mat-column-jan,
.mat-column-feb,
.mat-column-mar,
.mat-column-apr,
.mat-column-may,
.mat-column-jun,
.mat-column-jul,
.mat-column-aug,
.mat-column-sep,
.mat-column-oct,
.mat-column-nov,
.mat-column-dec,
.mat-column-total,
.mat-column-week_duration,
.mat-column-prev_week_duration {
    width: 1px !important;
}



.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}



.mat-drawer-inner-container_ {
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.mat-drawer-inner-container,
app-menu {
    height: 100%;
    display: flex;
}

.mat-drawer-container {
    background-color: #FFF9F9 !important;
}

.mat-drawer.mat-drawer-side {
    z-index: 1 !important;
}

.mat-sort-header-arrow {
    color: #E42D1F !important;
}

mat-paginator button:not([disabled="true"]) svg {
    fill: #E42D1F;
}

mat-paginator mat-select svg {
    fill: #E42D1F !important;
}

mat-spinner svg {
    stroke: #E42D1F !important;
}


.detail_page {
    margin-top: 18px;
}

.detail_page mat-tab-header {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 18px;
}

.detail_page mat-tab-header,
.detail_page .main_tab_body {
    margin-left: 3px;
    margin-right: 3px;
    -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.detail_page mat-tab-header {
    padding: 0px 24px;
}

.main_tab_body {
    padding: 18px 24px;
    background-color: white;
    margin-top: 3px;
    margin-bottom: 3px;
}

mat-tab-body {
    margin-bottom: 24px;
}

.xl-select-field {
    width: 400px;
    max-width: 100%;
}


html {
    --mdc-checkbox-selected-icon-color: #E42D1F;
    --mdc-checkbox-selected-hover-icon-color: #E42D1F;
    --mdc-checkbox-selected-focus-icon-color: #E42D1F;
    --mdc-checkbox-selected-active-icon-color: #E42D1F;
    --mdc-checkbox-selected-hover-state-layer-color: #E42D1F;
    --mdc-checkbox-selected-focus-state-layer-color: #E42D1F;
    --mdc-checkbox-selected-pressed-state-layer-color: #E42D1F;
    --mat-option-selected-state-label-text-color: #E42D1F;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #E42D1F;

    --mat-badge-background-color: #E42D1F;
    --mat-datepicker-calendar-date-selected-state-background-color: #E42D1F;
    --mat-datepicker-toggle-active-state-icon-color: #E42D1F;
    --mat-full-pseudo-checkbox-selected-icon-color: #E42D1F;
    --mat-icon-button-state-layer-color: #E42D1F;
    --mat-icon-color: #E42D1F;
    --mat-outlined-button-state-layer-color: #E42D1F;
    --mat-radio-checked-ripple-color: #E42D1F;
    --mat-slider-ripple-color: #E42D1F;
    --mat-stepper-header-done-state-icon-background-color: #E42D1F;
    --mat-stepper-header-edit-state-icon-background-color: #E42D1F;
    --mat-stepper-header-selected-state-icon-background-color: #E42D1F;
    --mat-tab-header-active-focus-indicator-color: #E42D1F;
    --mat-tab-header-active-focus-label-text-color: #E42D1F;
    --mat-tab-header-active-hover-indicator-color: #E42D1F;
    --mat-tab-header-active-hover-label-text-color: #E42D1F;
    --mat-tab-header-active-label-text-color: #E42D1F;
    --mat-tab-header-active-ripple-color: #E42D1F;
    --mat-tab-header-inactive-ripple-color: #E42D1F;
    --mat-tab-header-with-background-background-color: #E42D1F;
    --mat-text-button-state-layer-color: #E42D1F;
    --mat-toolbar-container-background-color: #E42D1F;
    --mdc-checkbox-selected-pressed-icon-color: #E42D1F;
    --mdc-chip-elevated-container-color: #E42D1F;
    --mdc-chip-elevated-disabled-container-color: #E42D1F;
    --mdc-chip-elevated-selected-container-color: #E42D1F;
    --mdc-chip-flat-disabled-selected-container-color: #E42D1F;
    --mdc-circular-progress-active-indicator-color: #E42D1F;
    --mdc-fab-container-color: #E42D1F;
    --mdc-fab-small-container-color: #E42D1F;
    --mdc-filled-button-container-color: #E42D1F;
    --mdc-filled-text-field-caret-color: #E42D1F;
    --mdc-filled-text-field-focus-active-indicator-color: #E42D1F;
    --mdc-icon-button-icon-color: #E42D1F;
    --mdc-linear-progress-active-indicator-color: #E42D1F;
    --mdc-outlined-button-label-text-color: #E42D1F;
    --mdc-outlined-text-field-caret-color: #E42D1F;
    --mdc-outlined-text-field-focus-outline-color: #E42D1F;
    --mdc-protected-button-container-color: #E42D1F;
    --mdc-radio-selected-focus-icon-color: #E42D1F;
    --mdc-radio-selected-hover-icon-color: #E42D1F;
    --mdc-radio-selected-icon-color: #E42D1F;
    --mdc-radio-selected-pressed-icon-color: #E42D1F;
    --mdc-slider-active-track-color: #E42D1F;
    --mdc-slider-focus-handle-color: #E42D1F;
    --mdc-slider-handle-color: #E42D1F;
    --mdc-slider-hover-handle-color: #E42D1F;
    --mdc-slider-inactive-track-color: #E42D1F;
    --mdc-slider-with-tick-marks-inactive-container-color: #E42D1F;
    --mdc-tab-indicator-active-indicator-color: #E42D1F;
    --mdc-text-button-label-text-color: #E42D1F;
    --mat-snack-bar-button-color: #E42D1F;
    --mdc-protected-button-label-text-color: #fff;

    --mdc-filled-text-field-focus-label-text-color: #E42D1F;
    --mdc-outlined-text-field-focus-label-text-color: #E42D1F;
    --mat-form-field-focus-select-arrow-color: #E42D1F;

    --mat-full-pseudo-checkbox-selected-icon-color: #E42D1F;

    --mat-datepicker-calendar-date-selected-disabled-state-background-color: #e42c1f90;
    --mat-datepicker-calendar-date-focus-state-background-color: #e42c1f90;
    --mat-datepicker-calendar-date-in-range-state-background-color: #e42c1f90;
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #e42c1f90;
    --mat-datepicker-calendar-date-hover-state-background-color: #e42c1f56;

    --mdc-switch-selected-focus-state-layer-color: #E42D1F;
    --mdc-switch-selected-handle-color: #E42D1F;
    --mdc-switch-selected-hover-state-layer-color: #E42D1F;
    --mdc-switch-selected-pressed-state-layer-color: #E42D1F;
    --mdc-switch-selected-focus-handle-color: #E42D1F;
    --mdc-switch-selected-hover-handle-color: #E42D1F;
    --mdc-switch-selected-pressed-handle-color: #E42D1F;
    --mdc-switch-selected-focus-track-color: #e42c1f56;
    --mdc-switch-selected-hover-track-color: #e42c1f56;
    --mdc-switch-selected-pressed-track-color: #e42c1f56;
    --mdc-switch-selected-track-color: #e42c1f56;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #E42D1F;
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: black;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: #E42D1F;
    --mat-tab-header-active-ripple-color: #E42D1F;
    --mat-tab-header-inactive-ripple-color: #E42D1F;
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #E42D1F;
    --mat-tab-header-active-hover-label-text-color: #E42D1F;
    --mat-tab-header-active-focus-indicator-color: #E42D1F;
    --mat-tab-header-active-hover-indicator-color: #E42D1F;
}

.mat-mdc-button.mat-primary {
    --mdc-text-button-label-text-color: #E42D1F;
    --mat-text-button-state-layer-color: #E42D1F;
    --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1);
}

.mat-step-icon-content {
    --mat-icon-color: #fff;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
    color: #E42D1F
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: #E42D1F !important;
}

.main-color {
    color: #E42D1F !important;
}

.boolean_icon_true {
    color: #3EC03A;
}

.boolean_icon_false {
    color: #161414;
}

.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #D44E30 !important;
}


.details_section_span {
    min-width: 250px;
    max-width: 100%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-block;
    margin-bottom: 12px;
    vertical-align: top;
}


@media screen and (max-width: 600px) {
    .details_section_span {
        min-width: 150px;
    }
}

@media screen and (max-width: 550px) {
    .mat-mdc-paginator-page-size-label {
        display: none;
    }

    .mat-mdc-paginator-range-label {
        margin: 0 24px 0 16px;
    }
}

.mat-drawer-container[fullscreen] {
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    position: relative !important;
    min-height: 100vh;
}

.mat-drawer-content {
    min-height: calc(100vh - var(--mat-toolbar-standard-height));
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media (min-width: 768px) {
    .mat-drawer-content {
        min-height: 100vh;
    }
}

@media (max-width: 599px) {
    .mat-drawer-content {
        min-height: calc(100vh - var(--mat-toolbar-mobile-height));
    }
}

.mat-mdc-form-field-icon-prefix>.mat-icon,
.mat-mdc-form-field-icon-suffix>.mat-icon {
    padding: 0 10px 0px 0 !important;
}